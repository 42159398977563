import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-help"
    }}>{`Getting help`}</h1>
    <p className="lead">
  Stay up to date on the development of React-Bootstrap and reach out to the
  community with these helpful resources.
    </p>
    <h2 {...{
      "id": "stack-overflow"
    }}>{`Stack Overflow`}</h2>
    <p><a parentName="p" {...{
        "href": "http://stackoverflow.com/questions/ask"
      }}>{`Ask questions`}</a>{` about specific problems you have faced, including details about what exactly
you are trying to do. Make sure you tag your question with
`}<inlineCode parentName="p">{`react-bootstrap`}</inlineCode>{`. You can also read through `}<a parentName="p" {...{
        "href": "http://stackoverflow.com/questions/tagged/react-bootstrap"
      }}>{`existing React-Bootstrap questions`}</a>{`.`}</p>
    <h2 {...{
      "id": "chat-rooms"
    }}>{`Chat rooms`}</h2>
    <p>{`Discuss questions in the `}<code className="js">{`#react-bootstrap`}</code>{`
channel on the `}<a parentName="p" {...{
        "href": "https://discord.gg/AKfs9vpvRW"
      }}>{`Reactiflux Discord`}</a>{`.`}</p>
    <h2 {...{
      "id": "github-issues"
    }}>{`GitHub issues`}</h2>
    <p>{`The issue tracker is the preferred channel for bug reports, features
requests and submitting pull requests. See more about how we use issues
in the `}<a parentName="p" {...{
        "href": "https://github.com/react-bootstrap/react-bootstrap/blob/master/CONTRIBUTING.md#issues"
      }}>{`contribution guidelines`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      